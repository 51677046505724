import React from "react";
import PageHeader from "./components/PageHeader";
import PageContent from "./components/PageContent";
import Nav from "./components/Nav";
import Helmet from "react-helmet";
import MyCV from "./assets/Param Damle CV.pdf";

const CVPage = (props) => (
  <>
    <Helmet>
      <title>Curriculum Vitae | Param Damle</title>
    </Helmet>
    <Nav />
    <div>
      <PageHeader title="Curriculum Vitae" />
      <PageContent> 
        You may have trouble viewing this file if you are not using Google Chrome on desktop.
        <div style={{display:"flex", justifyContent: "center", width:"100%", height:"100vh"}}> 
        {/* <embed src={MyCV} width="800px" height="2100px" /> */}
          <object data={MyCV} 
// "./assets/Param Damle CV.pdf" 
                width="100%"
                height="100%"> 
        </object>
        </div>
      </PageContent>
    </div>
  </>
);

export default CVPage;
