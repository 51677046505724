import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Tab = styled.div`
  width: 100vmin;
  // height: 15vmin;
  border-radius: 3vmin;
  margin-top: 2vmin;
  margin-bottom: 2vmin;
  padding: 2vmin;
`;

const TabHeader = styled.h1`
  font-size: 4vmin;
  text-decoration: none !important;
  margin-top: 0vmin;
  margin-left: 15%;
  margin-bottom: 0vmin;
  padding-top: 0vmin;
`;
const TabHeaderLabel = styled.span`
  margin-left: 1vmin;
  font-size: 2vmin;
`;

const TabText = styled.h2`
  font-size: 2vmin;
  margin-top: 0vmin;
  margin-left: 15%;
  font-weight: normal;
  padding: 0vmin;
`;

const TabIconContainer = styled.div`
  aspect-ratio: 1 / 1;
  float: left;
  max-width: 13%;
`

const TabIcon = styled.img`
  width:100%;   
  height:100%;
  object-fit: contain;
`;

const ProjectTab = ({
  name,
  img,
  desc,
  toLink = "",
  label,
  linkType = "component",
  colors = ["black", "#383838", "white", "#690c00"],
}) => {
  const innerContent = () => {
    return (
      <div style={{display:"flex", justifyContent:"center"}}>
      <Tab style={{ color: colors[0], background: colors[2] }}>
        <TabIconContainer><TabIcon src={img}></TabIcon></TabIconContainer>
        <TabHeader>
          {name}
          <TabHeaderLabel style={{ color: colors[3] }}>{label}</TabHeaderLabel>
        </TabHeader>
        <TabText style={{ color: colors[1] }}>{desc}</TabText>
      </Tab>
      </div>
    );
  };

  if (linkType === "redirect") {
    return (
      <div
        onClick={() => {
          window.open(toLink, "_self");
        }}
        style={{ cursor: "pointer" }}
      >
        {innerContent()}
      </div>
    );
  }
  if (linkType === "ext") {
    return (
      <div
        onClick={() => {
          window.open(toLink, "_blank");
        }}
        style={{ cursor: "pointer" }}
      >
        {innerContent()}
      </div>
    );
  }
  if (linkType === "component") {
    return (
      <Link to={toLink} style={{ "text-decoration": "none" }}>
        {innerContent()}
      </Link>
    );
  }
  return innerContent();
};

export default ProjectTab;
