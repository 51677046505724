import React from "react";
import PageHeader from "./components/PageHeader";
import PageContent from "./components/PageContent";
import Nav from "./components/Nav";
import Helmet from "react-helmet";
import BioPic from "./assets/images/biopic.jpg";

const About = (props) => (
  <>
    <Helmet>
      <title>About Me | Param Damle</title>
    </Helmet>
    <Nav />
    <div>
      <PageHeader title="About Me!" />
      <PageContent>
        <img
          src={BioPic}
          alt="Param Damle"
          style={{
            width: "20vw",
            float: "left",
            marginTop: "2vh",
            marginLeft: "2vh",
            marginRight: "5vh",
            padding: "2vh"
          }}
        />
        <div style={{ marginTop: "2vh", marginLeft: "2vh", padding: "2vh", width: "80vw" }}>
          <h1>Hello! I'm Param. Welcome to my website.</h1>
          <p style={{ fontSize: "3vh" }}>
            I'm a graduate computer science student at Carnegie Mellon University. I previously
            attended the University of Virginia, where I started this website to experiment with 
            React and other web technologies I found interesting.
            <br /> 
            Since then, I have come a long way in my interests.
            <ul style={{paddingLeft: "2vh"}}>
              <li>After teaching Discrete Math and Algorithms, my work in education led to
                projects like SmartUp and my TuneScope research project, which allowed me 
                to travel to SIGCSE in Canada and KDD in California to present my work.
              </li>
              <li>Pursuing a growing interest in algorithmic economics drove me to contribute
                to a competition on AICrowd, an independent Blackjack bot, and the start of a
                paper on contract resolution. I also explored the field by interning at the most innovative
                firms in finance, where I had the good fortune of working with the world's best and brightest.
              </li>
              <li>When studying abroad at the National University of Singapore in Spring 2023, I 
                got the chance to study Quantum Computing, and became inspired to share my learning with my
                peers back home. I adapted machine learning into my version of the curriculum and
                designed/taught DS 5559 Quantum Machine Learning, a graduate research course, at 
                the UVA School of Data Science in Spring 2024.
              </li>
            </ul>
            <br />
            So you can probably see how designing websites fell a little to the wayside. 
            What haven't fallen to the wayside are my interests in YouTube podcasts, discovering indie 
            pop and rock songs, trying out spicy sauces and regretting it later, writing/producing my 
            own music, playing with my unendingly energetic dog, and traveling around the regions of the world
            where I can encounter the kinds of stories that deserve more attention.
            <br />
            <br />
            If you want to connect, feel free to <a target="blank" style={{color:"white"}} href="https://www.linkedin.com/in/paramdamle/">send me a LinkedIn message!</a>
          </p>
        </div>
      </PageContent>
    </div>
  </>
);

export default About;
