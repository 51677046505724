import React, { useRef, Suspense } from "react";
import { FontLoader } from "three";
import { Canvas, useFrame, extend, useThree } from "react-three-fiber";
import { Helmet } from "react-helmet";

import { OrbitControls } from "../../../node_modules/three/examples/jsm/controls/OrbitControls";

import HelveticaNeue from "./HelveticaNeue.json";

// import "./App.css";

extend({ OrbitControls });
function Cube() {
  const pi = 3.14159265359265358979323846264338327950288419;
  return (
    <mesh rotation={[pi / 5, pi / 4, 0]} position={[0, 0, 0]}>
      <boxGeometry attach="geometry" args={[1.5, 1.5, 1.5]} />
      {/* <meshStandardMaterial
        attach="material"
        color="white"
        opacity={0.6}
        // roughness={1}
        // metalness={1}

      /> */}
      <meshPhongMaterial
        color="white"
        // envMap={}
        refractionRatio={0.98}
        reflectivity={0.9}
        metalness={1}
        wireframe={true}
      />
    </mesh>
  );
}

function Loading() {
  return (
    <mesh rotation={[0, 0, 0]}>
      <sphereGeometry attach="geometry" args={[1, 16, 16]} />
      <meshStandardMaterial
        attach="material"
        color="white"
        transparent
        opacity={0.6}
        roughness={1}
        metalness={0}
      />
    </mesh>
  );
}

function CameraControls() {
  const {
    camera,
    gl: { domElement },
  } = useThree();

  const controls = useRef();
  camera.position.set(0, 0, 0.33);

  useFrame((state) => {
    controls.current.update();
  });
  return <orbitControls ref={controls} args={[camera, domElement]} />;
}

function Quote(text) {
  const font = new FontLoader().parse(HelveticaNeue);
  const textOptions = {
    font,
    size: 0.04,
    height: 0.007,
    curveSegments: 12,
    bevelEnabled: true,
    bevelThickness: 0.001,
    bevelSize: 0.0015,
    bevelSegments: 20,
  };
  const textMesh = useRef();
  const textGeo = useRef();

  // var geometry = new TextGeometry(text, textOptions);

  // geometry.computeBoundingBox();
  // geometry.center();
  // textMesh.current &&
  //   textMesh.current.geometry &&
  //   textMesh.current.geometry.dispose();
  // textMesh.current && (textMesh.current.geometry = geometry);
  // if (textGeo.current) {
  //   textGeo.current.computeBoundingBox();
  //   console.log(textGeo.current.boundingBox.getCenter() );
  // }
  return (
    <mesh ref={textMesh} position={[-0.25, -0.01, 0]}>
      <textGeometry ref={textGeo} args={[text, textOptions]} />
      <meshStandardMaterial
        transparent
        opacity={0.9}
        roughness={0.9}
        metalness={0.2}
      />
    </mesh>
  );
}

function ThreeDSpace() {
  return (
    <>
      <Helmet>
        <script
          src="../node_modules/three/examples/js/controls/OrbitControls"
          type="text/javascript"
        />
      </Helmet>
      <div
        style={{
          height: window.innerHeight,
          // flex: 1,
          // height: "100%",
        }}
      >
        <Canvas style={{ 
background: "linear-gradient(90deg, rgba(255,146,194,1) 0%, rgba(133,198,254,1) 100%)",}}>
          <CameraControls />
          <directionalLight intensity={0.6} />
          <ambientLight intensity={0.5} />
          <spotLight position={[0, 0, 2]} />
          <Suspense fallback={<Loading />}>
            <Cube />
            {Quote("think outside the box.")}
          </Suspense>
        </Canvas>
      </div>
    </>
  );
}

export default ThreeDSpace;
