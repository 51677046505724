// src/components/PageHeader.js
import React from "react";
import styled from "styled-components";

const Header = styled.div`
  background: linear-gradient(
    90deg,
    rgba(255, 146, 194, 1) 0%,
    rgba(133, 198, 254, 1) 100%
  );
  padding: 1rem 2rem;
`;

const Title = styled.h1`
  font-weight: 300;
`;

const PageHeader = (props) => (
  <Header>
    <Title>{props.title}</Title>
  </Header>
);

export default PageHeader;
