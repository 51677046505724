import React from "react";
import PageContent from "./components/PageContent";
import PageHeader from "./components/PageHeader";
import Nav from "./components/Nav";
import Helmet from "react-helmet";
import ProjectTab from "./projects/projectTab";
import styled from "styled-components";

import iconSite from "./projects/icons/arrow.png";
import iconSwapi from "./projects/icons/starWars.png";
import iconMirror from "./projects/icons/magicMirror.png";
import iconListen from "./projects/icons/listenParty.png";
import iconGreenGarments from "./projects/icons/greenGarments.png";
import iconEcoFootprint from "./projects/icons/ecoFootprint.png";
import iconBlackjack from "./projects/icons/blackjack.png"
import iconSmartUp from "./projects/icons/smartUp.png"
import iconNLPMentalHealth from "./projects/icons/redFlag.png"
import iconSnapAutograder from "./projects/icons/snapCode.png"
import iconMLLifeExpectancy from "./projects/icons/heartNet.png"
import iconQuantum from "./projects/icons/quantumChip.png"

const TabbedPage = styled.div`
  justify-content: center;
  margin: 0 auto;
`;

const Projects = (props) => {
  return (
    <>
      <Helmet>
        <title>Projects | Param Damle</title>
      </Helmet>
      <Nav />
      <div>
        <PageHeader title="My (Coding) Projects" />
        <PageContent>
          <ProjectTab
            name="This Website"
            linkType="none"
            img={iconSite}
            desc="You're on it! Built from scratch using React JS. Will continue to be updated as I learn more. The home page was made using Three.JS and react-three-fiber."
            colors={[
              "white",
              "black",
              "linear-gradient(90deg, rgba(255,146,194,1) 0%, rgba(133,198,254,1) 100%)",
              "red",
            ]}
          ></ProjectTab>

          <ProjectTab
            name="Quantum Machine Learning"
            linkType="none"
            img={iconQuantum}
            desc="I'm currently preparing material for an original course, DS 5559 Quantum Machine Learning, that I will teach with Prof Gianluca Guadagni at the UVA Graduate School of Data Science (Spring '24)"
            colors={[
              "#defcf1",
              "#cffcf7",
              "linear-gradient(90deg, rgba(10,37,48,1) 0%, rgba(23,31,41,1) 100%)",
              "red",
            ]}
          ></ProjectTab>

          <ProjectTab
            name="NLP for Mental Health Detection"
            toLink="https://youtu.be/mDPYVzjAQg4"
            linkType="ext"
            label="[YouTube]"
            img={iconNLPMentalHealth}
            desc="I led a team in designing an ensemble model that identifies mental health red flags in text samples from social media for CS 6501: Natural Language Processing (Fall '23)"
            colors={[
              "#c70000",
              "#851010",
              "#dec683",
              "#543d17",
            ]}
          ></ProjectTab>

          <ProjectTab
            name="Autograder for Snap!"
            toLink="https://kdd.org/kdd2023/wp-content/uploads/2023/08/damle2023extracting.pdf"
            linkType="ext"
            label="[KDD Paper]"
            img={iconSnapAutograder}
            desc="I built feature extraction model for Snap! code with PI N. Rich Nguyen. Presented at SIGCSE TS 2023 (Toronto) and had peer-reviewed paper accepted to KDD UC 2023 (Long Beach)."
            colors={[
              "#0a000a",
              "#1a0924",
              "#f2ebf2",
              "#008cff",
            ]}
          ></ProjectTab>

          <ProjectTab
            name="Algorithmic Blackjack Agents"
            toLink="https://youtu.be/qSKaci_2B64"
            linkType="ext"
            label="[YouTube]"
            img={iconBlackjack}
            desc="I compiled and trained reinforcement learning strategies to thrive in a novel blackjack setup for CS 6501 Algorithmic Economics (Spring '22)."
            colors={[
              "#00fff7",
              "#8affeb",
              "linear-gradient(90deg, rgba(0,43,15,1) 0%, rgba(4,15,6,1) 100%)",
              "#c40a4e",
            ]}
          ></ProjectTab>

          <ProjectTab
            name="SmartUp!"
            toLink="https://youtu.be/VJm9TjtDZKY"
            linkType="ext"
            label="[YouTube]"
            img={iconSmartUp}
            desc="Search engine that sorts results by reading level to support learners of all levels and backgrounds. Won 1st Place at HooHacks Education (Spring '22)"
            colors={[
              "black",
              "black",
              "#f0d0bb",
              "#f08297",
            ]}
          ></ProjectTab>

          <ProjectTab
            name="ML for Life Expectancy Analysis"
            toLink="https://youtu.be/UFx4SpoT_k0"
            linkType="ext"
            label="[YouTube]"
            img={iconMLLifeExpectancy}
            desc="Used clustering to highlight contributors and suggest improvements to life expectancy. Won 1st plact at ML4VA Competition in CS 4774 Machine Learning (Fall '21)"
            colors={[
              "#ffa200",
              "#e8b22a",
              "#19181f",
              "#ff8800",
            ]}
          ></ProjectTab>

          
          <ProjectTab
            name="Green Garments"
            toLink="https://github.com/paramsdamle/GreenGarments"
            linkType="ext"
            label="[Github]"
            img={iconGreenGarments}
            desc="Chrome Extension that reports fashion products' and websites' sustainability. Written in Javascript for HooHacks (Spring '21)"
            colors={["#43f665", "white", "#0d1117", "#aefbbd"]}
          ></ProjectTab>

          <ProjectTab
            name="EcoFootprint"
            toLink="https://github.com/paramsdamle/ecofootprint"
            linkType="ext"
            label="[Github]"
            img={iconEcoFootprint}
            desc="App where you can track your daily habits and their impact on the environment. Written in Flutter (Android) for Google's DSC Solution Challenge (Spring '21)"
            colors={["black", "#6a524a", "#efebe9", "#66cc99"]}
          ></ProjectTab>

          <ProjectTab
            name="Star Wars - API Test"
            toLink="/SwapiTest"
            img={iconSwapi}
            label="[Work In Progress!]"
            desc="My first experimentation with API data retrieval. Uses classic Star Wars API and (soon) Google Images API."
            colors={["#efb087", "#d6bf7c", "#442f14", "#61a0db"]}
          ></ProjectTab>

          <ProjectTab
            name="Magic Mirror"
            toLink="/MagicMirror"
            img={iconMirror}
            label="[Work In Progress!]"
            desc="Uses Face-API.js to detect and draw facial recognition landmarks on user's webcam footage."
            colors={["black", "black", "#faebd7", "#d5e1e3"]}
          ></ProjectTab>

          <ProjectTab
            name="Listen Party"
            toLink="/ListenParty"
            img={iconListen}
            label="[Coming Soon]"
            desc="Uses Spotify API to gather playlist song synchronization info."
            colors={["white", "gray", "black", "#1DB954"]}
          ></ProjectTab>
        </PageContent>
      </div>
    </>
  );
};

export default Projects;
